import { useSession } from "@blitzjs/auth"
import { Routes } from "@blitzjs/next"
import { Login as LoginIcon } from "@mui/icons-material"
import React, { Suspense } from "react"
import { useTheme } from "@mui/material/styles"
import Drawer from "@mui/material/Drawer"
import SidebarNav from "./SidebarNav"
import { Box, Button } from "@mui/material"
import UserMenu from "src/core/layouts/UserMenu"
import { NextLinkComposed } from "src/core/components/Link"

interface Props {
  onClose: () => void
  open: boolean
  variant: "permanent" | "persistent" | "temporary" | undefined
}

const UserAuth = () => {
  const session = useSession()

  if (session?.userId) return <UserMenu />
  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Button
        startIcon={<LoginIcon />}
        variant="contained"
        component={NextLinkComposed}
        to={Routes.LoginPage()}
        fullWidth
      >
        Login
      </Button>
    </Box>
  )
}

const Sidebar = ({ open, variant, onClose }: Props): JSX.Element => {
  const theme = useTheme()
  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 256,
          top: { xs: 0, md: 67 },
          height: { xs: "100%", md: "calc(100% - 67px)" },
          background: theme.palette.alternate.main,
        },
      }}
    >
      <Box my={2} display="flex" justifyContent="center">
        <Suspense>
          <UserAuth />
        </Suspense>
      </Box>
      <SidebarNav />
    </Drawer>
  )
}

export default Sidebar
