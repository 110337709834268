import Head from "next/head"
import { BlitzLayout } from "@blitzjs/next"
import { alpha, AppBar, Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Suspense, useCallback, useState } from "react"
import getConfig from "next/config"
import Sidebar from "./Sidebar"
import Topbar from "./Topbar"
import { useTranslations } from "next-intl"
import { AppEnvBar } from "src/core/layouts/AppEnvBar"
import { ImpersonatingUserNotice } from "src/auth/components/ImpersonatingUserNotice"

const config = getConfig()

const Layout: BlitzLayout<{ title?: string; children?: React.ReactNode }> = ({
  title,
  children,
}) => {
  const t = useTranslations("common")
  const theme = useTheme()

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = useCallback((): void => {
    setOpenSidebar(true)
  }, [])

  const handleSidebarClose = useCallback((): void => {
    setOpenSidebar(false)
  }, [])

  const open = isMd ? false : openSidebar

  return (
    <>
      <Head>
        <title>{t("pageTitle", { title: title || "Course" })}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Box>
        <AppBar
          position={"fixed"}
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          }}
          elevation={0}
        >
          <Box sx={{ py: { xs: 1, sm: 0.4 } }}>
            <Suspense>
              <Topbar onSidebarOpen={handleSidebarOpen} />
            </Suspense>
          </Box>
        </AppBar>
        <Sidebar onClose={handleSidebarClose} open={open} variant={"temporary"} />
        <Box component="main" sx={{ mb: 6 }}>
          <Box height={{ xs: 58, sm: 60 }} />
          <Suspense>
            <ImpersonatingUserNotice />
          </Suspense>
          <Box display="flex" flex="1 1 auto" overflow="hidden">
            <Box display="flex" flex="1 1 auto" overflow="hidden">
              <Box flex="1 1 auto" minHeight="100%" overflow="auto">
                {children}
              </Box>
            </Box>
          </Box>

          <AppEnvBar />
          <Box position="fixed" bottom={0} right={10}>
            <Typography component="small" variant="subtitle2">
              Version: {config?.publicRuntimeConfig?.version}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Layout
