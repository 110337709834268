import { Toolbar, Typography } from "@mui/material"
import { red } from "@mui/material/colors"

const NO_SHOW = ["production", "development"]

export const AppEnvBar = () => {
  const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV?.toLowerCase() || "development"
  if (NO_SHOW.includes(APP_ENV)) return null

  return (
    <Toolbar
      sx={{
        position: "fixed",
        width: "100%",
        bottom: "0px",
        "&.MuiToolbar-root": {
          height: 48,
          minHeight: 48,
        },
        background: red[500],
      }}
    >
      <Typography variant="body1" color="#fff" component="div" align="center" fontWeight={500}>
        You are using <u>{APP_ENV}</u> server.
      </Typography>
    </Toolbar>
  )
}
