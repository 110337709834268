import { useSession } from "@blitzjs/auth"
import { invoke } from "@blitzjs/rpc"
import { Alert, Button } from "@mui/material"
import stopImpersonating from "src/auth/mutations/stopImpersonating"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"

export const ImpersonatingUserNotice = () => {
  const session = useSession()
  if (!session.impersonatingFromUserId) return null

  const handleStopImpersonating = async () => {
    await invoke(stopImpersonating, {})
    queryClient.clear()
    if (typeof window !== "undefined") {
      window.location.replace("/admin#/User")
    }
  }

  return (
    <Alert
      severity="warning"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      Currently impersonating user {session.userId}{" "}
      <Button
        variant="contained"
        color="secondary"
        size="small"
        endIcon={<ExitToAppIcon />}
        onClick={handleStopImpersonating}
      >
        Exit
      </Button>
    </Alert>
  )
}
