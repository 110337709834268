import Image from "next/image"
import { useRouter } from "next/router"
import { Routes } from "@blitzjs/next"
import { alpha, useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import MenuIcon from "@mui/icons-material/Menu"
import { FC } from "react"
import UserMenu from "src/core/layouts/UserMenu"
import { RouteUrlObject } from "blitz"
import Link, { NextLinkComposed } from "src/core/components/Link"
import { Login as LoginIcon } from "@mui/icons-material"
import { useCurrentUser } from "src/core/hooks/useCurrentUser"
import { useHasAnyRole } from "src/core/hooks/useHasAnyRole"
import { useTranslations } from "next-intl"
import { HelpButton } from "src/core/components/HelpButton"
import { Container, Stack } from "@mui/material"

interface Props {
  onSidebarOpen: () => void
}

const NavLink = (props: { label: string; href: RouteUrlObject; exact?: boolean }) => {
  const { label, href, exact } = props
  const router = useRouter()
  const theme = useTheme()
  const active = exact
    ? router.pathname === href.pathname
    : router.pathname.startsWith(href.pathname)

  return (
    <Link
      sx={{
        "&:hover": {
          color: theme.palette.primary.light,
        },
        textDecorationThickness: 3,
        textDecorationColor: theme.palette.primary.light,
      }}
      underline={active ? "always" : "none"}
      color={active ? "primary" : "text.secondary"}
      fontWeight={active ? 700 : 600}
      fontSize={16}
      fontFamily="Roboto, sans-serif"
      href={href}
    >
      {label}
    </Link>
  )
}

const Topbar: FC<Props> = ({ onSidebarOpen }) => {
  const t = useTranslations("common")
  const hasAnyRole = useHasAnyRole()
  const user = useCurrentUser()
  const theme = useTheme()

  return (
    <Container maxWidth="xl">
      <Box display={"flex"} alignItems={"center"} width={1}>
        <Stack
          sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          spacing={4}
          direction="row"
        >
          <Box>
            <Link href="/" sx={{ display: "flex" }}>
              <Image
                src="/logo.png"
                title="KMITL"
                width={116 * 0.75}
                height={46 * 0.75}
                alt="KMITL"
              />
            </Link>
          </Box>
          <Box>
            <NavLink href={Routes.Home()} label={t("Home")} exact />
          </Box>
          {hasAnyRole(["TEACHER", "STAFF"]) && (
            <Box>
              <NavLink href={Routes.MyIndexPage()} label={t("My Courses")} />
            </Box>
          )}
          {user?.isProgramManager && (
            <Box>
              <NavLink href={Routes.ProgramManagerIndexPage()} label={t("Program Manager")} />
            </Box>
          )}
          {hasAnyRole(["SCHOOL_MANAGER", "ADMIN"]) && (
            <Box>
              <NavLink href={Routes.AdminIndexPage()} label={t("School Manager")} />
            </Box>
          )}
        </Stack>
        <Box flex={1} />
        <Box display={"flex"} alignItems="center">
          <Box>
            <HelpButton />
          </Box>
          <Box marginLeft={{ xs: 1, md: 2 }} display={{ xs: "none", md: "block" }}>
            {user ? (
              <UserMenu />
            ) : (
              <Button
                startIcon={<LoginIcon />}
                variant="outlined"
                component={NextLinkComposed}
                to={Routes.LoginPage()}
              >
                {t("Login")}
              </Button>
            )}
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" } }} marginLeft={2}>
            <Button
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={"outlined"}
              sx={{
                borderRadius: 2,
                minWidth: "auto",
                padding: 1,
                borderColor: alpha(theme.palette.divider, 0.2),
              }}
            >
              <MenuIcon />
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Topbar
