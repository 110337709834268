import { User } from "db"

const UserHelper = {
  fullnameTh: (user: Partial<User>) => {
    const fullname = [user.firstNameTh, user.lastNameTh].filter(Boolean).join(" ")
    if (user.titleTh) {
      return `${user.titleTh} ${fullname}`.trim()
    }
    return fullname
  },
  fullnameEn: (user: Partial<User>) => {
    const fullname = [user.firstNameEn, user.lastNameEn].filter(Boolean).join(" ")
    if (user.titleEn) {
      return `${user.titleEn} ${fullname}`.trim()
    }
    return fullname
  },
  fullname: (user: Pick<User, "firstNameEn" | "lastNameEn" | "firstNameTh" | "lastNameTh">) => {
    const en = UserHelper.fullnameEn(user)
    if (en) {
      return en
    }
    return UserHelper.fullnameTh(user)
  },
}

export default UserHelper
