import { useRouter } from "next/router"
import { Routes } from "@blitzjs/next"
import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { RouteUrlObject } from "blitz"
import { NextLinkComposed } from "src/core/components/Link"
import { alpha, useTheme } from "@mui/material"
import { useHasAnyRole } from "src/core/hooks/useHasAnyRole"
import { useCurrentUser } from "src/core/hooks/useCurrentUser"
import { useTranslations } from "next-intl"

const NavLink = (props: {
  label: string
  href: RouteUrlObject
  icon?: React.ReactNode
  exact?: boolean
}) => {
  const { label, href, icon, exact } = props
  const router = useRouter()
  const theme = useTheme()
  const active = exact
    ? router.pathname === href.pathname
    : router.pathname.startsWith(href.pathname)

  return (
    <Button
      component={NextLinkComposed}
      to={href}
      fullWidth
      sx={{
        justifyContent: "flex-start",
        color: active ? undefined : "text.primary",
        bgcolor: active ? alpha(theme.palette.primary.light, 0.15) : undefined,
      }}
      startIcon={icon || null}
    >
      {label}
    </Button>
  )
}

const SidebarNav = (): JSX.Element => {
  const t = useTranslations("common")
  const hasAnyRole = useHasAnyRole()
  const user = useCurrentUser()

  return (
    <Box padding={2}>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          marginBottom: 1,
          display: "block",
        }}
      >
        Menu
      </Typography>
      <Box marginBottom={1 / 2}>
        <NavLink href={Routes.Home()} label={t("Home")} exact />
      </Box>
      {hasAnyRole(["TEACHER", "STAFF"]) && (
        <Box marginBottom={1 / 2}>
          <NavLink href={Routes.MyIndexPage()} label={t("My Courses")} />
        </Box>
      )}
      {user?.isProgramManager && (
        <Box>
          <NavLink href={Routes.ProgramManagerIndexPage()} label={t("Program Manager")} />
        </Box>
      )}
      {hasAnyRole(["SCHOOL_MANAGER", "ADMIN"]) && (
        <Box>
          <NavLink href={Routes.AdminIndexPage()} label={t("School Manager")} />
        </Box>
      )}
    </Box>
  )
}

export default SidebarNav
