import { useCurrentUser } from "src/core/hooks/useCurrentUser"
import { useCallback } from "react"
import { Role } from "types"

export function useHasAnyRole() {
  const user = useCurrentUser()
  const roles = user?.roles

  return useCallback(
    (roleOrRoles: Role | Role[]) => {
      if (!roles) return false
      if (!Array.isArray(roleOrRoles)) roleOrRoles = [roleOrRoles]
      return roleOrRoles.some((role) => roles.includes(role))
    },
    [roles]
  )
}
