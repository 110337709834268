import { HelpCenter as HelpIcon } from "@mui/icons-material"
import { Button } from "@mui/material"

const URL = "https://bit.ly/3yGejkM"

export const HelpButton = () => {
  return (
    <Button
      aria-label="help"
      color="primary"
      target="_blank"
      href={URL}
      startIcon={<HelpIcon />}
      variant="outlined"
    >
      Help
    </Button>
  )
}
