import { useRouter } from "next/router"
import { useMutation } from "@blitzjs/rpc"
import { Routes } from "@blitzjs/next"
import * as React from "react"
import {
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Button,
  Avatar,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import PopupState, { bindToggle, bindPopover } from "material-ui-popup-state"
import { useCurrentUser } from "src/core/hooks/useCurrentUser"
import { AdminPanelSettings, ExitToApp as ExitToAppIcon } from "@mui/icons-material"
import logout from "src/auth/mutations/logout"
import { deepOrange } from "@mui/material/colors"
import UserHelper from "src/users/userHelper"
import { NextLinkComposed } from "src/core/components/Link"
import { useHasAnyRole } from "src/core/hooks/useHasAnyRole"

const UserMenu = () => {
  const user = useCurrentUser()
  const hasAnyRoles = useHasAnyRole()
  const router = useRouter()
  const [logoutMutation] = useMutation(logout, {
    onSuccess: () => {
      router.push(Routes.LoginPage())
    },
  })

  const theme = useTheme()

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  if (!user) return null

  return (
    <PopupState variant="popover" popupId="user-menu-popover">
      {(popoverState) => (
        <div>
          <Button {...bindToggle(popoverState)} variant="text" sx={{ textAlign: "left" }}>
            <Box>
              <Avatar
                sx={(theme) => ({
                  width: { xs: 28, sm: 32 },
                  height: { xs: 28, sm: 32 },
                  bgcolor: theme.palette.primary.main,
                })}
              >
                {user.firstNameEn?.[0] || user.firstNameTh?.[0] || user.email?.[0]}
              </Avatar>
            </Box>
            <Box ml={1}>
              <Typography variant="subtitle2" color="textPrimary" component="div">
                {UserHelper.fullnameEn(user)}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="div" fontSize="0.805rem">
                {user.email}
              </Typography>
            </Box>
          </Button>
          <Popover
            {...bindPopover(popoverState)}
            disablePortal={isMd}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              sx: {
                width: 260,
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <List disablePadding>
              {hasAnyRoles("ADMIN") && (
                <ListItem divider>
                  <Chip label="ADMIN" size="small" color="primary" />
                </ListItem>
              )}
              <ListItem divider>
                <ListItemText
                  primary={UserHelper.fullnameEn(user)}
                  secondary={
                    <React.Fragment>
                      {user.email} <br />
                      {user.faculty?.nameEn}
                    </React.Fragment>
                  }
                  primaryTypographyProps={{ textAlign: "left" }}
                  secondaryTypographyProps={{ textAlign: "left" }}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={async () => {
                    await logoutMutation()
                  }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="ออกจากระบบ" />
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

export default UserMenu
